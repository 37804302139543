<template>
    <div class="wrapper">
        <div class="list" v-for="(item,index) in list" :key="index">
            <div class="item">
                <div class="titleBox">
                    <div class="titleImg"></div>
                    <div class="title">{{ item.title }}</div>
                </div>
                <div class="imgBox">
                    <div class="name">卡面</div>
                    <div class="img">
                        <img :src="item.img1" alt="">
                    </div>
                    <div class="img">
                        <img :src="item.img2" alt="">
                    </div>
                </div>
                <div class="cardBox">
                    <div class="name">名称</div>
                    <div class="cardName">{{ item.cardName1 }}</div>
                    <div class="cardName">{{ item.cardName2 }}</div>
                </div>
                <div class="degreeHeatBox">
                    <div class="name"></div>
                    <div class="degreeHeat">
                        <img src="@/assets/images/degreeHeatImg.png" alt="" v-for="(it,index) in item.degreeHeat1" :key="index">
                    </div>
                    <div class="degreeHeat">
                        <img src="@/assets/images/degreeHeatImg.png" alt="" v-for="(it,index) in item.degreeHeat2" :key="index">
                    </div>
                </div>
                <div class="cardType">
                    <div class="box">
                        <div class="typeBox">
                            <div class="name">卡级别</div>
                            <div class="cardImg"></div>
                            <div class="typeName">白金卡</div>
                        </div>
                        <div class="typeBox">
                            <div class="name">卡种</div>
                            <div class="cardImg"></div>
                            <div class="typeName">标准卡</div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="typeBox">
                            <div class="cardImg"></div>
                            <div class="typeName">白金卡</div>
                        </div>
                        <div class="typeBox">
                            <div class="cardImg"></div>
                            <div class="typeName">标准卡</div>
                        </div>
                    </div>
                </div>
                <div class="itemList" v-for="(it,idx) in item.list" :key="idx" >
                    <template v-if="it.key&&it.itemList.length>0">
                        <div class="titleBox" v-show="it.title">
                            <div class="titleImg"></div>
                            <div class="title">{{ it.title }}</div>
                        </div>
                        
                        <div :class="its.propName?'items':'items itemsbox'" v-for="(its,inx) in it.itemList" :key="inx" v-show="its.name!='卡级别'&&its.name!='卡种'">
                            <div class="name" v-show="its.propName">{{ its.propName }}</div>
                            <div :class="its.propName?'left':'left lefts'">
                                <div class="text">{{ its.text1 }}</div>
                            </div>
                            
                            <div :class="its.propName?'right':'right rights'">
                                <div class="text">{{ its.text2 }}</div>
                            </div>
                        </div>

                    </template>

                    <!-- <template v-else-if="!it.key&&it.itemList.length>0">
                        <div v-for="(i,t) in it.itemList" :key="t">
                            <div  class="titleBox" style="margin-bottom: .2rem;margin-top: .2rem;">
                                <div class="titleImg"></div>
                                <div class="title">{{ i.name }}</div>
                            </div>
                            <div class="items"   v-show="i.name!='卡级别'&&i.name!='卡种'">
                                <div class="left">
                                    <div class="text">{{ i.text1 }}</div>
                                </div>
                                
                                <div class="right">
                                    <div class="text">{{ i.text2 }}</div>
                                </div>
                            </div>

                        </div>
                    </template> -->

                    

                </div>

            </div>

        </div>
    </div>
</template>

<script>
import {rank,getCharacters} from '@/api'
export default {
    components: {},
    props: {},
    data() {
        return {
            lastRecordId:'',
            dataModel:[],
            list:[],
            data:[{
                title:'基本信息',
                img1:require('@/assets/images/img4.png'),
                img2:require('@/assets/images/img5.png'),
                cardName1:'银联&VISA双标建行大山白',
                cardName2:'中信I白金卡',
                degreeHeat1:3,//热度
                degreeHeat2:5,
                list:[{
                    text1:'法律空手道解放昆仑山搭街坊立刻数量的客服经理空手道解放撒旦立刻发酵饲料的看法利克斯酱豆腐立刻',
                    text2:'/',
                    name:'申卡条件参考'
                },{
                    text1:'/',
                    text2:'法律空手道解放昆仑山搭街坊立刻数量的客服',
                    name:'年费'
                }]
            }]
        }
    },
    watch: {},
    computed: {},
    methods: {
        getCharacters(){
            getCharacters({
                ids:this.dataModel
            }).then(res=>{
                let data = res.data.data
                this.list = [{
                    title:'基本信息',
                    img1:data[0].avatar,
                    img2:data[1].avatar,
                    cardName1:data[0].name,
                    cardName2:data[1].name,
                    degreeHeat1:5,//热度
                    degreeHeat2:5,
                    //list:[]
                }]
                let itemList = []
                let groupProps = []
                data[0].props.forEach(ele => {
                    itemList.push({
                        text1:ele.value,
                        text2:'',
                        name:ele.key
                    })
                });
                data[0].propList.forEach(ele => {
                    groupProps.push({
                        value:ele.value,
                        name:ele.name,
                        key:ele.key,
                        index:ele.index
                    })
                });
                let xt = []
                data[1].props.forEach(ele => {
                    itemList.forEach((li,index)=>{
                        if(li.name == ele.key){
                            itemList[index].text2 = ele.value
                        }
                    })
                    let bt = itemList.filter(item=>{
                        return item.name != ele.key
                    })
                    if(itemList.length == bt.length){
                        itemList.push({
                            text1:'',
                            text2:ele.value,
                            name:ele.key
                        })
                    }

                });
                data[1].propList.forEach(ele => {
                    let bt = groupProps.filter(item=>{
                        return item.key != ele.key
                    })
                    if(groupProps.length == bt.length){
                        groupProps.push({
                            value:ele.value,
                            name:ele.name,
                            key:ele.key,
                            index:ele.index
                        })
                    }

                });
                let groupPropsList = []
                let tail = []
                let tailList = []
                groupProps.forEach(ele=>{
                    let itemArr = []
                    ele.value.forEach(val=>{
                        itemList.forEach(item=>{
                            if(val.propKey == item.name&&(item.text1||item.text2)){
                                itemArr.push({
                                    propName:val.propName,
                                    ...item
                                })
                            }
                            // if(val.propKey == item.name&&(item.text1||item.text2)&&!val.propName){
                            //     tailList.push({
                            //         propName:val.propName,
                            //         ...item
                            //     })
                            //     tail.push({
                            //         title:ele.name,
                            //         key:ele.key,
                            //         itemList:tailList
                            //     })
                            // }
                        })
                    })

                    groupPropsList.push({
                        title:ele.name,
                        key:ele.key,
                        index:ele.index,
                        itemList:itemArr
                    })
                })
                
                
                let sortedArray = groupPropsList.sort((a, b) => a.index - b.index);

                this.list[0].list = sortedArray
                // this.list[0].list.push(...tail)

                let itemToMove = []
                let indexs = ''

                // this.list[0].list.forEach((ele,index)=>{
                //     if(ele.key == '基本信息'){
                //         indexs = index
                //     }
                // })
                // itemToMove = this.list[0].list.splice(indexs, 1)[0]
                // this.list[0].list.unshift({
                //     ...itemToMove
                // })
                console.log(this.list[0].list)
                // this.list[0].list.forEach(ele=>{
                //     if(ele.itemList.length == 0){
                //         ele.title = ''
                //     }
                // })
                

                // console.log(xt)
                // console.log(bt)

            })

        },
        getRank(){
            // let param = {
            //     storyId:0,
            //     sceneId:0,
            //     eventId:0,
            //     ...sessionStorage.getItem('obj')?JSON.parse(sessionStorage.getItem('obj')):{}
            // }
            rank({
                recordId:this.lastRecordId
            }).then(res=>{
                this.dataModel = res.data.data
                this.getCharacters()
            })
        },
        getUrlParams(url){
            const Params = {}
            if(url.indexOf('?')>0){//判断是否有qurey
                let parmas = url.slice(url.indexOf('?')+1)//截取出query
                const paramlists = parmas.split('&')//分割键值对
                for (const param of paramlists) {
                    let a = param.split('=')
                    Object.assign(Params,{[a[0]]:a[1]})//将键值对封装成对象
                }
            }
            return Params
        }
    },
    created() { 
        let url = window.location.href
        let obj = this.getUrlParams(url)
        this.lastRecordId = obj.lastRecordId
        this.getRank()
    },
    mounted() { }
}
</script>
<style scoped lang="scss">
.wrapper {}
.list{
    width: 7rem;
    margin: .3rem auto 0 auto;
    padding-bottom: .2rem;

    .item{
        width: 7rem;

        .itemList{
            width: 6.8rem;
            margin-top: .1rem;

            .titleBox{
                margin-bottom: .2rem;
            }

            .itemsbox{
                border:none !important;
            }

            .items{
                width: 6.8rem;
                height: auto;
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;
                border: .02rem solid #F3F2EF;


                .name {
                    width: .5rem;
                    align-self: stretch; /* 让.name元素高度随.items元素变化而变化 */
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: .24rem;
                    text-align: center; /* 文本居中 */
                    font-weight: bold;
                    padding: 0 .15rem;
                }

                .lefts{
                    width: 3.4rem !important;
                    border: none !important;
                }

                .left{
                    width: 3rem;
                    background-color: #FAFCFF;
                    align-self: stretch; /* 让.name元素高度随.items元素变化而变化 */
                    border-left: .02rem solid #F3F2EF;

                    .text{
                        width: 2.8rem;
                        white-space: pre-wrap;
                        font-size: .24rem;
                        color: #505052;
                        margin: .1rem auto;
                    }

                }
                .rights{
                    width: 3.4rem !important;
                    border: none !important;
                }
                .right{
                    width: 3rem;
                    background-color: #FFFDF1;
                    align-self: stretch; /* 让.name元素高度随.items元素变化而变化 */
                    border-left: .02rem solid #F3F2EF;

                    .text{
                        width: 2.8rem;
                        white-space: pre-wrap;
                        font-size: .24rem;
                        color: #3C3B36;
                        margin: .1rem auto;
                    }

                }

            }


        }

        .cardType{
            width: 6.8rem;
            height: auto;
            display: flex;
            justify-content: space-between;
            margin-top: .1rem;
            .name {
                width: .5rem;
                align-self: stretch; /* 让.name元素高度随.items元素变化而变化 */
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: .24rem;
                text-align: center; /* 文本居中 */
                font-weight: bold;
                padding: 0 .15rem;
            }
            .box{
                width: 3rem;
            }
            .box .typeBox:nth-child(2){
                margin-top: .1rem;
            }
            .typeBox:nth-child(1){
                border-bottom: .02rem solid #F3F2EF;

            }
            .typeBox{
                display: flex;
                height: .7rem;
                padding-bottom: .02rem;
                align-items: center;

                .cardImg{
                    width: .32rem;
                    height: .3rem;
                    background-image: url(~@/assets/images/cardImg.png);
                    background-size: 100% auto;
                    background-position: top;
                    background-repeat: no-repeat;
                }

                .typeName{
                    font-size: .24rem;
                    height: .3rem;
                    line-height: .3rem;
                    margin-left: .1rem;
                    color: #989898;
                }
            }

        }

        .degreeHeatBox{
            width: 6.8rem;
            height: auto;
            display: flex;
            justify-content: space-between;
            margin-top: .1rem;
            border-bottom: .02rem solid #F3F2EF;
            .name {
                width: .5rem;
                align-self: stretch; /* 让.name元素高度随.items元素变化而变化 */
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: .24rem;
                text-align: center; /* 文本居中 */
                font-weight: bold;
                padding: 0 .15rem;
            }
            .degreeHeat{
                width: 3rem;
                height: .4rem;
                display: flex;
                .img{
                    max-height: 100%;
                    width: auto;
                }
            }
        }

        .cardBox{
            width: 6.8rem;
            height: auto;
            display: flex;
            justify-content: space-between;
            margin-top: .3rem;
            .name {
                width: .5rem;
                align-self: stretch; /* 让.name元素高度随.items元素变化而变化 */
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: .24rem;
                text-align: center; /* 文本居中 */
                font-weight: bold;
                padding: 0 .15rem;
            }
            .cardName{
                width: 3rem;
                font-size: .28rem;
                line-height: .3rem;
                font-weight: bold;
            }

        }

        .imgBox{
            width: 6.8rem;
            height: auto;
            display: flex;
            justify-content: space-between;
            border-bottom: .02rem solid #F3F2EF;

            .name {
                width: .5rem;
                align-self: stretch; /* 让.name元素高度随.items元素变化而变化 */
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: .24rem;
                text-align: center; /* 文本居中 */
                font-weight: bold;
                padding: 0 .15rem;
            }

            .img{
                width: 3rem;
                height: 1.87rem;
                margin-top: .3rem;
                img{
                    width: 2.44rem;
                    height: 1.54rem;
                }
            }
        }

        .titleBox{
            height: .35rem;
            display: flex;
            align-items: center;

            .titleImg{
                width: .35rem;
                height: .35rem;
                background-image: url(~@/assets/images/titleImg.png);
                background-size: 100% auto;
                background-position: top;
                background-repeat: no-repeat;
            }

            .title{
                margin-left: .1rem;
                height: .35rem;
                line-height: .4rem;
                font-size: .35rem;
                font-weight: bold;
            }
        }
    }

}
</style>